import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import Switch from "react-switch";
import {
  arrayOf,
  func,
  number,
  shape,
  string,
} from "prop-types";
import { dispatchCodePropType, menuItemPropType } from "shared/prop-types";
import { MOBILE_TECHNICIAN_INTEGRATION_KIND, PICK_UP_INTEGRATION_KIND } from "shared/constants";
import { saveMenuItemAdditionalSettings } from "store/actions/menu-template-actions";
import {
  dmsTypeSelector,
  settingsIntegrationsSelector,
  settingsLaborTypesSelector,
} from "store/selectors/settings-selectors";
import Button from "components/common/Button";
import StyledSelect from "components/common/StyledSelect";

import "./styles.scss";

const AdditionalSettings = ({
  menuItem = {},
  onSaveMenuItem,
  integrations,
  laborTypes,
  dmsType,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [cutoffTime, setCutoffTime] = useState(null);
  const [isDefaultAvailable, setIsDefaultAvailable] = useState(false);
  const [addToEveryInhouseRO, setaddToEveryInhouseRO] = useState(false);
  const [addToEveryMobileRO, setaddToEveryMobileRO] = useState(false);
  const [addToEveryPickupRO, setaddToEveryPickupRO] = useState(false);
  const [availableForProactive, setAvailableForProactive] = useState(false);
  const [laborType, setLaborType] = useState(null);

  const integrationKinds = integrations.map((integration) => integration.kind);
  const mobileTechnicianIntegration = integrationKinds.includes(MOBILE_TECHNICIAN_INTEGRATION_KIND);
  const pickUpIntegration = integrationKinds.includes(PICK_UP_INTEGRATION_KIND);

  const everyRoButtonChecked = () => {
    if (mobileTechnicianIntegration && pickUpIntegration && addToEveryInhouseRO) {
      return addToEveryMobileRO && addToEveryPickupRO;
    }

    if (mobileTechnicianIntegration && !pickUpIntegration && addToEveryInhouseRO) {
      return addToEveryMobileRO;
    }

    if (!mobileTechnicianIntegration && pickUpIntegration && addToEveryInhouseRO) {
      return addToEveryPickupRO;
    }

    return addToEveryInhouseRO;
  };

  const laborTypeOptions = (laborTypes || []).map((element) => {
    return {
      label: `${element.code} - ${element.description}`,
      code: element.code,
      id: element.id,
    };
  });

  useEffect(() => {
    setaddToEveryInhouseRO(menuItem.default_inhouse_repair_order_item);
    setaddToEveryMobileRO(menuItem.default_mobile_repair_order_item);
    setaddToEveryPickupRO(menuItem.default_pickup_repair_order_item);
    setIsDefaultAvailable(menuItem.available_on_weekends);
    setCutoffTime(menuItem.cutoff_time);
    setAvailableForProactive(menuItem.available_for_proactive);
    setLaborType(menuItem.labor_type);
  }, [menuItem]);

  const handleEveryROClick = (value) => {
    setaddToEveryInhouseRO(value);

    if (mobileTechnicianIntegration) {
      setaddToEveryMobileRO(value);
    }

    if (pickUpIntegration) {
      setaddToEveryPickupRO(value);
    }
  };
  const laborLabel = () => {
    if (laborType) {
      return `${laborType?.code} - ${laborType?.description}`;
    }

    return "";
  };

  return (
    <div className="additionalGroup">
      <div className="additionalGroupWrapper">
        <div className="additionalGroupInputWrapper">
          <div className="additionalGroupInputContainer">
            <span className="additionalGroupInputLabel">
              Last appointment cut-off time from dealerships closing time (hours in decimals)
            </span>
            <input
              type="number"
              step="0.5"
              min={0}
              max={8}
              className="additionalGroupInputTextInput"
              disabled={!isEditing}
              value={cutoffTime}
              onChange={(e) => setCutoffTime(Number(e.target.value))}
            />
          </div>
          <div className="additionalGroupInputContainer">
            <div className="additionalGroupSwitchContainer">
              <label
                className="additionalGroupSwitchLabel"
                htmlFor="add-available"
              >
                Available on weekends
              </label>
              <Switch
                id="available-switch"
                className="additionalGroupSwitchToggle"
                onChange={() => {
                  setIsDefaultAvailable(!isDefaultAvailable);
                }}
                checked={isDefaultAvailable}
                disabled={!isEditing}
                onColor="#36af5e"
                offColor="#dedee0"
                activeBoxShadow="0 0 2px 3px #0bcaf9"
                aria-labelledby="add-available"
              />
            </div>
            {menuItem.kind === "maintenance" && (
              <div className="additionalGroupSwitchContainer">
                <label
                  className="additionalGroupSwitchLabel"
                  htmlFor="add-available"
                >
                  Available for Proactive Scheduling
                </label>
                <Switch
                  id="available-switch"
                  className="additionalGroupSwitchToggle"
                  onChange={() => {
                    setAvailableForProactive(!availableForProactive);
                  }}
                  checked={availableForProactive}
                  disabled={!isEditing}
                  onColor="#36af5e"
                  offColor="#dedee0"
                  activeBoxShadow="0 0 2px 3px #0bcaf9"
                  aria-labelledby="add-available"
                />
              </div>
            )}
          </div>
          {dmsType === "cdk" && (
            <StyledSelect
              label="Labor type"
              key="labor_type"
              options={laborTypeOptions}
              value={{
                label: laborType?.label ?? laborLabel(),
                value: laborType?.code ?? "",
              }}
              onChange={(event) => setLaborType(event)}
              disabled={!isEditing}
              withEmptyOption
            />
          )}
        </div>
        <div className="additionalGroupToggleButtonWrapper">
          <span className="additionalGroupToggleButtonWrapperLabel">
            Automatically add to RO
          </span>
          {(mobileTechnicianIntegration || pickUpIntegration) && (
            <div className="additionalGroupToggleButtonRow">
              <Button
                variant={everyRoButtonChecked() ? "aqua" : "neutral"}
                className={cx("additionalGroupToggleButton", {
                  additionalGroupToggleButtonDisabled: !isEditing,
                })}
                onClick={() => {
                  if (isEditing) {
                    handleEveryROClick(!everyRoButtonChecked());
                  }
                }}
              >
                Every
              </Button>
            </div>
          )}
          <div className="additionalGroupToggleButtonRow">
            <Button
              variant={addToEveryInhouseRO ? "aqua" : "neutral"}
              className={cx("additionalGroupToggleButton", {
                additionalGroupToggleButtonDisabled: !isEditing,
              })}
              withoutBorder={false}
              onClick={() => {
                if (isEditing) {
                  setaddToEveryInhouseRO(!addToEveryInhouseRO);
                }
              }}
            >
              In-house
            </Button>
            {mobileTechnicianIntegration && (
              <Button
                variant={addToEveryMobileRO ? "aqua" : "neutral"}
                className={cx("additionalGroupToggleButton", {
                  additionalGroupToggleButtonDisabled: !isEditing,
                })}
                onClick={() => {
                  if (isEditing) {
                    setaddToEveryMobileRO(!addToEveryMobileRO);
                  }
                }}
              >
                Mobile technician
              </Button>
            )}
            {pickUpIntegration && (
              <Button
                variant={addToEveryPickupRO ? "aqua" : "neutral"}
                className={cx("additionalGroupToggleButton", {
                  additionalGroupToggleButtonDisabled: !isEditing,
                })}
                onClick={() => {
                  if (isEditing) {
                    setaddToEveryPickupRO(!addToEveryPickupRO);
                  }
                }}
              >
                Pickup-up and delivery
              </Button>
            )}
          </div>
        </div>
      </div>
      {isEditing ? (
        <div className="additionalGroupActionButtons">
          <Button
            variant="brand"
            onClick={() => {
              setIsEditing(false);
              onSaveMenuItem(
                menuItem.id,
                {
                  cutoff_time: cutoffTime,
                  available_on_weekends: isDefaultAvailable,
                  default_inhouse_repair_order_item: addToEveryInhouseRO,
                  default_mobile_repair_order_item: addToEveryMobileRO,
                  default_pickup_repair_order_item: addToEveryPickupRO,
                  available_for_proactive: availableForProactive,
                  labor_type_id: laborType.id || null,
                },
              );
            }}
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="additionalGroupActionButtons">
          <Button
            variant="dark"
            leftIcon="editWhite"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

AdditionalSettings.propTypes = {
  menuItem: menuItemPropType.isRequired,
  onSaveMenuItem: func.isRequired,
  integrations: arrayOf(shape({
    id: number.isRequired,
    kind: string.isRequired,
  })),
  laborTypes: arrayOf(dispatchCodePropType).isRequired,
  dmsType: string.isRequired,
};

AdditionalSettings.defaultProps = {
  integrations: [],
};

const mapStateToProps = (state) => {
  return {
    integrations: settingsIntegrationsSelector(state),
    laborTypes: settingsLaborTypesSelector(state),
    dmsType: dmsTypeSelector(state),
  };
};

const actions = {
  onSaveMenuItem: saveMenuItemAdditionalSettings,
};

const AdditionalSettingsContainer = connect(mapStateToProps, actions)(AdditionalSettings);

export default AdditionalSettingsContainer;
