import React from "react";
import {
  arrayOf, bool, func, node, shape, string,
} from "prop-types";
import Select from "react-select";
import cx from "classnames";

import "./styles.scss";

const StyledSelect = ({
  label,
  value,
  className,
  onChange,
  onInputChange,
  options,
  error,
  disabled,
  isOptionDisabled,
  withEmptyOption,
  menuPlacement,
}) => {
  const selectOptions = () => {
    const emptyElement = {
      label: "",
      value: "",
    };

    if (withEmptyOption) {
      options.unshift(emptyElement);

      return options;
    }

    return options;
  };

  return (
    <>
      {label && <div className="StyledSelect StyledSelectLabel">{label}</div>}
      <Select
        isSearchable
        className={cx("StyledSelect", className)}
        classNamePrefix="StyledSelect"
        value={value}
        menuPlacement={menuPlacement}
        onInputChange={onInputChange}
        onChange={onChange}
        options={selectOptions()}
        isDisabled={disabled}
        isOptionDisabled={isOptionDisabled}
        withEmptyoption={withEmptyOption}
      />
      <p className="conciergeInputError">{error}</p>
    </>
  );
};

StyledSelect.propTypes = {
  label: string,
  value: shape({
    label: string,
    value: node,
  }),
  className: string,
  onChange: func,
  options: arrayOf(shape({
    label: string,
    value: node,
  })),
  error: string,
  disabled: bool,
  isOptionDisabled: func,
  withEmptyOption: bool,
  onInputChange: func,
  menuPlacement: string,
};

StyledSelect.defaultProps = {
  value: null,
  withEmptyOption: false,
  label: null,
  className: "",
  onChange: () => {},
  options: [],
  error: null,
  disabled: false,
  isOptionDisabled: () => {},
  onInputChange: () => { },
  menuPlacement: "auto",
};

export default StyledSelect;
