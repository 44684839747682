import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  arrayOf, bool, func, oneOfType, shape,
} from "prop-types";
import { dealershipInfoPropType, serviceAdvisorPropType } from "shared/prop-types";
import "react-notifications/lib/notifications.css";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";

import { bulkUpdateAdvisors, changeDealershipInfo } from "store/actions/settings-actions";
import {
  dealershipMakeModelYearMapSelector,
  delershipOpCodeMapSelector,
} from "store/selectors/app-selectors";
import { convertArrayToSelectOptions } from "shared/utils/common";
import { emailValidator } from "shared/validators";

import Accordion from "components/common/Accordion";
import Block from "components/common/Block";
import Input from "components/common/Input";
import StyledSelect from "components/common/StyledSelect";
import HeaderEdit from "components/common/HeaderEdit";
import { activeAdvisorsSelector, phantomAdvisorAvailableSelector } from "store/selectors/settings-selectors";
import DualListBox from "react-dual-listbox";
import OpCodesSelect from "components/common/OpCodesSelect";

import "./styles.scss";

const DealershipSettingsPanel = ({
  dealershipInfo,
  makeModelYearMap,
  updateDealership,
  phantomAdvisorAvailable,
  serviceAdvisors,
  updateAdvisors,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [salesEmails, setSalesEmails] = useState([]);
  const [additionalSettings, setAdditionalSettings] = useState({});
  const [carInspectionEnabled, setCarInspectionEnabled] = useState(true);
  const [fallbackOpCode, setFallbackOpCode] = useState(null);
  const [servicesPriceVisible, setServicesPriceVisible] = useState(true);
  const [repairOrderGenerationOnArrival, setRepairOrderGenerationOnArrival] = useState(false);
  const [salesEmailError, setSalesEmailError] = useState(null);
  const [salesEmailInputValue, setSalesEmailInputValue] = useState("");
  const [appraisalEmailAdfFormatEnabled, setAppraisalEmailAdfFormatEnabled] = useState(false);
  const [appraisalEmailDataEmailAddress, setAppraisalEmailDataEmailAddress] = useState("");
  const [appraisalEmailValueError, setAppraisalEmailValueError] = useState(null);
  const [appraisalEmailEmptyError, setAppraisalEmailEmptyError] = useState(null);
  const [appraisalPhoneNumberEmptyError, setAppraisalPhoneNumberEmptyError] = useState(null);
  const [appraisalDealershipUrlEmptyError, setAppraisalDealershipUrlEmptyError] = useState(null);
  const [appraisalEmailDataPhoneNumber, setAppraisalEmailDataPhoneNumber] = useState("");
  const [appraisalEmailDataDealershipUrl, setAppraisalEmailDataDealershipUrl] = useState("");
  const [phantomAvailable, setPhantomAvailable] = useState(phantomAdvisorAvailable);
  const [avatarAvailable, setAvatarAvailable] = useState(false);
  const [unselectedServiceAdvisors, setUnselectedServiceAdvisors] = useState(
    serviceAdvisors
      .map((advisor) => {
        return {
          value: advisor.id,
          label: advisor.name,
        };
      }),
  );
  const [phantomAdvisors, setPhantomAdvisors] = useState(
    serviceAdvisors
      .filter((advisor) => advisor.is_phantom)
      .map((advisor) => advisor.id),
  );

  useEffect(() => {
    setPhantomAvailable(phantomAdvisorAvailable);
  }, [phantomAdvisorAvailable]);

  useEffect(() => {
    setUnselectedServiceAdvisors(serviceAdvisors
      .map((advisor) => {
        return {
          value: advisor.id,
          label: advisor.name,
        };
      }));
    setPhantomAdvisors(
      serviceAdvisors
        .filter((advisor) => advisor.is_phantom)
        .map((advisor) => advisor.id),
    );
  }, [serviceAdvisors]);

  useEffect(() => {
    setSalesEmails(dealershipInfo.sales_emails);
    setAdditionalSettings(dealershipInfo.additional_settings);
    setCarInspectionEnabled(dealershipInfo.car_inspection_enabled);
    setServicesPriceVisible(dealershipInfo.services_price_visible);
    setRepairOrderGenerationOnArrival(dealershipInfo.repair_order_generation_on_arrival);
    setAppraisalEmailAdfFormatEnabled(dealershipInfo.appraisal_email_adf_format_enabled);
    setAppraisalEmailDataEmailAddress(dealershipInfo.appraisal_email_data_email_address);
    setAppraisalEmailDataPhoneNumber(dealershipInfo.appraisal_email_data_phone_number);
    setAppraisalEmailDataDealershipUrl(dealershipInfo.appraisal_email_data_dealership_url);
    setSalesEmailInputValue("");
    setFallbackOpCode({
      label: dealershipInfo.fallback_op_code?.description,
      description: dealershipInfo.fallback_op_code?.description,
      id: dealershipInfo.fallback_op_code?.id,
      value: dealershipInfo.fallback_op_code?.id,
      operation_code: dealershipInfo.fallback_op_code?.operation_code,
    });
    setAvatarAvailable(dealershipInfo.widget_avatar_enabled);
  }, [dealershipInfo]);

  const validateEmailAssigned = () => {
    const isValid = (
      appraisalEmailAdfFormatEnabled
      && appraisalEmailDataEmailAddress !== ""
      && appraisalEmailDataEmailAddress !== null
    );

    if (isValid) {
      setAppraisalEmailEmptyError(null);
      return true;
    }
    setAppraisalEmailEmptyError("Email must be assigned if ADF format is enabled");
    return false;
  };

  const validatePhoneNumberAssigned = () => {
    const isValid = (
      appraisalEmailAdfFormatEnabled
      && appraisalEmailDataPhoneNumber !== ""
      && appraisalEmailDataPhoneNumber !== null
    );

    if (isValid) {
      setAppraisalPhoneNumberEmptyError(null);
      return true;
    }
    setAppraisalPhoneNumberEmptyError("Appraisal email phone number must be assigned if ADF format is enabled");
    return false;
  };

  const validateDealershipUrlAssigned = () => {
    const isValid = (
      appraisalEmailAdfFormatEnabled
      && appraisalEmailDataDealershipUrl !== ""
      && appraisalEmailDataDealershipUrl !== null
    );

    if (isValid) {
      setAppraisalDealershipUrlEmptyError(null);
      return true;
    }
    setAppraisalDealershipUrlEmptyError("Dealership URL must be assigned if ADF format is enabled");
    return false;
  };

  const validateEmailAddressValue = () => {
    const emailValid = emailValidator(appraisalEmailDataEmailAddress);
    if (!emailValid) {
      if (appraisalEmailDataEmailAddress === "" || appraisalEmailDataEmailAddress === null) {
        setAppraisalEmailValueError(null);
        return true;
      }
      setAppraisalEmailValueError("Invalid email");
      return false;
    }

    setAppraisalEmailValueError(null);
    return true;
  };

  const validateData = () => {
    if (!appraisalEmailAdfFormatEnabled) {
      return true;
    }
    validatePhoneNumberAssigned();
    validateEmailAddressValue();
    validateEmailAssigned();
    validateDealershipUrlAssigned();

    if (validatePhoneNumberAssigned()
    && validateEmailAddressValue()
    && validateEmailAssigned()
    && validateDealershipUrlAssigned()) {
      return true;
    }

    return false;
  };

  const saveChanges = () => {
    if (validateData()) {
      if (phantomAvailable) {
        const advisorsPhantomParams = serviceAdvisors.map((advisor) => {
          return {
            id: advisor.id,
            is_phantom: phantomAdvisors.indexOf(advisor.id) !== -1,
          };
        });

        updateAdvisors({ service_advisors: advisorsPhantomParams });
      }

      const dataToSend = new FormData();

      if (fallbackOpCode && fallbackOpCode.value) {
        dataToSend.append("fallback_op_code_id", fallbackOpCode.value);
      }

      if (salesEmails.length) {
        salesEmails.map((item) => dataToSend.append("sales_emails[]", item));
      } else {
        dataToSend.append("sales_emails[]", []);
      }

      dataToSend.append("additional_settings", JSON.stringify(additionalSettings));
      dataToSend.append("services_price_visible", servicesPriceVisible);
      dataToSend.append("repair_order_generation_on_arrival", repairOrderGenerationOnArrival);
      dataToSend.append("car_inspection_enabled", carInspectionEnabled);
      dataToSend.append("appraisal_email_adf_format_enabled", appraisalEmailAdfFormatEnabled);
      dataToSend.append("appraisal_email_data_email_address", appraisalEmailDataEmailAddress || "");
      dataToSend.append("appraisal_email_data_phone_number", appraisalEmailDataPhoneNumber || "");
      dataToSend.append("appraisal_email_data_dealership_url", appraisalEmailDataDealershipUrl || "");
      dataToSend.append("phantom_advisor_enabled", phantomAvailable);
      dataToSend.append("widget_avatar_enabled", avatarAvailable);

      updateDealership(dealershipInfo.id, dataToSend, false);
      setIsEditing(false);
    }
  };

  const structuredMakes = (
    makeModelYearMap
      ? convertArrayToSelectOptions(Object.keys(makeModelYearMap))
      : []
  );

  const cancelChanges = () => {
    setSalesEmails(dealershipInfo.sales_emails);
    setAdditionalSettings(dealershipInfo.additional_settings);
    setCarInspectionEnabled(dealershipInfo.car_inspection_enabled);
    setServicesPriceVisible(dealershipInfo.services_price_visible);
    setRepairOrderGenerationOnArrival(dealershipInfo.repair_order_generation_on_arrival);
    setAppraisalEmailAdfFormatEnabled(dealershipInfo.appraisal_email_adf_format_enabled);
    setAppraisalEmailDataEmailAddress(dealershipInfo.appraisal_email_data_email_address);
    setAppraisalEmailDataPhoneNumber(dealershipInfo.appraisal_email_data_phone_number);
    setAppraisalEmailDataDealershipUrl(dealershipInfo.appraisal_email_data_dealership_url);
    setSalesEmailInputValue("");
    setFallbackOpCode({
      label: dealershipInfo.fallback_op_code?.description,
      description: dealershipInfo.fallback_op_code?.description,
      id: dealershipInfo.fallback_op_code?.id,
      value: dealershipInfo.fallback_op_code?.id,
      operation_code: dealershipInfo.fallback_op_code?.operation_code,
    });
    setPhantomAvailable(phantomAdvisorAvailable);
    setAvatarAvailable(dealershipInfo.widget_avatar_enabled);
    setPhantomAdvisors(
      serviceAdvisors
        .filter((advisor) => advisor.is_phantom)
        .map((advisor) => advisor.id),
    );
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    switch (event.code) {
      case "Enter":
      case "Tab":
      case "Space":
        if (!emailValidator(event.target.value.trim())) {
          setSalesEmailError("Invalid email");
          return;
        }
        setSalesEmails(salesEmails.concat(event.target.value.trim()));
        setSalesEmailInputValue("");
        break;
      default:
        setSalesEmailInputValue(event.target.value.trim());
    }
  };

  const validateAndSetPhoneNumber = (value) => {
    if (value.match((/^[0-9]+$/)) != null) {
      setAppraisalEmailDataPhoneNumber(value);
    } else if (value.length === 1 || value.length === 0) {
      setAppraisalEmailDataPhoneNumber("");
    }
  };

  return (
    <Accordion
      title="Dealership settings"
      customClass="settingsAccordion"
    >
      <div className="conciergePanelDealershipSettingsBlockWrapper">
        <Block className="conciergePanelDealershipSettingsBlock">
          <label
            htmlFor="default_make"
            id="default_make"
            key="default_make"
            className="dealershipSettingsLabel"
          >
            <span>Make</span>
            <StyledSelect
              value={{
                label: additionalSettings?.default_make,
                value: additionalSettings?.default_make,
              }}
              options={structuredMakes}
              className="dealershipSettingsSelect"
              disabled={!isEditing}
              onChange={(value) => setAdditionalSettings({
                ...additionalSettings,
                default_make: value.label,
              })}
              menuPlacement="bottom"
            />
          </label>
          <Input
            label="Dealer code"
            type="dealer_code"
            value={additionalSettings?.dealer_code}
            disabled={!isEditing}
            onChange={(value) => setAdditionalSettings({
              ...additionalSettings,
              dealer_code: value,
            })}
          />
          <label
            htmlFor="fallbackOpCode"
            id="fallbackOpCode"
            key="fallbackOpCode"
            className="dealershipSettingsLabel"
          >
            <span>Fallback OP Code</span>
            <OpCodesSelect
              opCode={fallbackOpCode?.operation_code}
              onChange={(value) => setFallbackOpCode(value)}
              isDisabled={!isEditing}
              showAssigned
            />
          </label>
          <div className="dealershipSettingsSwitchWrapper">
            <div className="dealershipSettingsSwitchContainer">
              <label
                className="conciergeInputLabel"
                htmlFor="services-price-visible"
              >
                Display service price
              </label>
              <Switch
                id="services-price-visible"
                className="dealershipSettingsSwitch"
                onChange={() => setServicesPriceVisible(!servicesPriceVisible)}
                disabled={!isEditing}
                checked={servicesPriceVisible}
                onColor="#36af5e"
                offColor="#dedee0"
                activeBoxShadow="0 0 2px 3px #0bcaf9"
                aria-labelledby="services-price-visible-label"
              />
            </div>
            <div className="dealershipSettingsSwitchContainer">
              <label
                className="conciergeInputLabel"
                htmlFor="autogenerate-ro-visible-visible"
              >
                Auto-generate RO
              </label>
              <Switch
                id="autogenerate-ro-visible"
                className="dealershipSettingsSwitch"
                onChange={() => setRepairOrderGenerationOnArrival(!repairOrderGenerationOnArrival)}
                disabled={!isEditing}
                checked={repairOrderGenerationOnArrival}
                onColor="#36af5e"
                offColor="#dedee0"
                activeBoxShadow="0 0 2px 3px #0bcaf9"
                aria-labelledby="autogenerate-ro-visible-visible-label"
              />
            </div>
            <div
              className="dealershipSettingsSwitchContainer"
              style={{ marginBottom: "20px" }}
            >
              <label
                className="conciergeInputLabel"
                htmlFor="car-inspection-visible"
              >
                Inspection sticker
              </label>
              <Switch
                id="car-inspection-visible"
                className="dealershipSettingsSwitch"
                onChange={() => setCarInspectionEnabled(!carInspectionEnabled)}
                disabled={!isEditing}
                checked={carInspectionEnabled}
                onColor="#36af5e"
                offColor="#dedee0"
                activeBoxShadow="0 0 2px 3px #0bcaf9"
                aria-labelledby="car-inspection-visible-visible-label"
              />
            </div>
          </div>
        </Block>
        <Block className="dealershipSettingsBlock">
          <div>
            <div className="salesEmailsSelectLabel">
              Sales Emails
            </div>
            <CreatableSelect
              components={{ DropdownIndicator: null }}
              isMulti
              inputValue={salesEmailInputValue}
              onInputChange={(value) => {
                setSalesEmailError(null);
                setSalesEmailInputValue(value);
              }}
              isClearable={false}
              menuIsOpen={false}
              onKeyDown={(e) => handleKeyDown(e)}
              value={convertArrayToSelectOptions(salesEmails)}
              onChange={(value) => {
                setSalesEmails(value.map((email) => email.label));
                setSalesEmailError(null);
              }}
              isDisabled={!isEditing}
              placeholder="Type in the email and press enter..."
              classNamePrefix="salesEmailsSelect"
              className="salesEmailsSelectContainer"
              options={convertArrayToSelectOptions(salesEmails)}

            />
            {salesEmailError && (
              <div className="dealershipSettingsBlockInputError">
                {salesEmailError}
              </div>
            )}
          </div>
        </Block>
        <Block className="dealershipSettingsBlock">
          <div className="dealershipSettingsSwitchContainer">
            <label
              className="conciergeInputLabel"
              htmlFor="adf-format"
            >
              Appraisal emails in ADF format
            </label>
            <Switch
              id="adf-format"
              className="Switch"
              onChange={() => setAppraisalEmailAdfFormatEnabled(!appraisalEmailAdfFormatEnabled)}
              disabled={!isEditing}
              checked={appraisalEmailAdfFormatEnabled}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="adf-format-label"
            />
          </div>
          {appraisalEmailAdfFormatEnabled && (
            <>
              <Input
                className="conciergeInput dealershipSettingsBlockInput"
                label="ADF provider contact address"
                type="appraisalEmailDataEmailAddress"
                value={appraisalEmailDataEmailAddress}
                disabled={!isEditing}
                onChange={(value) => setAppraisalEmailDataEmailAddress(value)}
                error={appraisalEmailValueError || appraisalEmailEmptyError}
              />
              <Input
                label="ADF provider phone number"
                type="appraisalEmailDataPhoneNumber"
                value={appraisalEmailDataPhoneNumber}
                disabled={!isEditing}
                onChange={(value) => validateAndSetPhoneNumber(value)}
                error={appraisalPhoneNumberEmptyError}
              />
              <Input
                label="ADF provider URL"
                type="appraisalEmailDataDealershipUrl"
                value={appraisalEmailDataDealershipUrl}
                disabled={!isEditing}
                onChange={(value) => setAppraisalEmailDataDealershipUrl(value)}
                error={appraisalDealershipUrlEmptyError}
              />
            </>
          )}
        </Block>
        <Block className="dealershipSettingsBlock">
          <div className="dealershipSettingsSwitchContainer">
            <label
              className="conciergeInputLabel"
              htmlFor="adf-format"
            >
              Enable house advisor allocation
            </label>
            <Switch
              id="adf-format"
              className="Switch"
              onChange={() => setPhantomAvailable(!phantomAvailable)}
              disabled={!isEditing}
              checked={phantomAvailable}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="adf-format-label"
            />
          </div>
          {phantomAvailable && (
            <DualListBox
              showHeaderLabels
              disabled={!isEditing}
              options={unselectedServiceAdvisors}
              selected={phantomAdvisors}
              onChange={(selected) => setPhantomAdvisors(selected)}
            />
          )}
        </Block>
        <Block className="dealershipSettingsBlock">
          <div className="dealershipSettingsSwitchContainer">
            <label
              className="conciergeInputLabel"
              htmlFor="adf-format"
            >
              Display avatar in widget
            </label>
            <Switch
              id="adf-format"
              className="Switch"
              onChange={() => setAvatarAvailable(!avatarAvailable)}
              disabled={!isEditing}
              checked={avatarAvailable}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="adf-format-label"
            />
          </div>
        </Block>
        <div className="dealershipSettingsEditButton">
          <HeaderEdit
            isEditing={isEditing}
            handleCancel={cancelChanges}
            handleEdit={() => setIsEditing(!isEditing)}
            handleSave={() => saveChanges()}
          />
        </div>
      </div>
    </Accordion>
  );
};

DealershipSettingsPanel.propTypes = {
  dealershipInfo: dealershipInfoPropType,
  updateDealership: func,
  makeModelYearMap: oneOfType(shape({ make: func }), func).isRequired,
  phantomAdvisorAvailable: bool.isRequired,
  serviceAdvisors: arrayOf(serviceAdvisorPropType),
  updateAdvisors: func.isRequired,
};

DealershipSettingsPanel.defaultProps = {
  dealershipInfo: null,
  updateDealership: () => {},
  serviceAdvisors: [],
};

const mapStateToProps = (state) => {
  return {
    makeModelYearMap: dealershipMakeModelYearMapSelector(state),
    opCodeMap: delershipOpCodeMapSelector(state),
    phantomAdvisorAvailable: phantomAdvisorAvailableSelector(state),
    serviceAdvisors: activeAdvisorsSelector(state),
  };
};

const actions = {
  updateDealership: changeDealershipInfo,
  updateAdvisors: bulkUpdateAdvisors,
};

export default connect(mapStateToProps, actions)(DealershipSettingsPanel);
